var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2 conteneur-page gradient-bleu"},[_c('v-card',{staticClass:"pb-2"},[_c('v-card-title',{staticClass:"align-center"},[_c('v-text-field',{staticClass:"align-center",attrs:{"test-auto":_vm.nomPage + '_rechercher',"autofocus":"","prepend-inner-icon":"mdi-magnify","label":"Rechercher","clearable":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"d-flex px-4 my-2"},[_vm._t("filtre")],2),_c('v-spacer'),(_vm.labelBoutonAjoutElement !== '' && _vm.estResponsable)?_c('v-btn',{staticClass:"gradient-bleu",attrs:{"test-auto":_vm.nomPage + '_bouton_cree',"dark":""},on:{"click":function($event){return _vm.$emit('ajout-element')}}},[_c('v-icon',[_vm._v(" mdi-plus-circle-outline ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_vm._v(" "+_vm._s(_vm.labelBoutonAjoutElement)+" ")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.titresTable,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"items":_vm.itemsTable,"height":_vm.computeHeightForTable,"item-key":_vm.keyName,"dense":"","fixed-header":"","loading":_vm.loading,"mobile-breakpoint":_vm.mobileBreakpoint,"sort-by":_vm.defaultSortBy,"search":_vm.search},on:{"click:row":function (e) { return _vm.$emit('ligne-selectionnee', e); }},scopedSlots:_vm._u([(_vm.titresTable.map(function (header) { return header.value; }).includes('role'))?{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{class:("role role-id-" + (item.idRole))},[_vm._v(_vm._s(item.role))])]}}:null,(_vm.titresTable.map(function (header) { return header.value; }).includes('actif'))?{key:"item.actif",fn:function(ref){
var item = ref.item;
return [_c('div',{class:("etat " + (item.actif ? 'actif' : 'desactive'))},[_vm._v(" "+_vm._s(item.actif ? "actif" : "désactivé")+" ")])]}}:null,(_vm.titresTable.map(function (header) { return header.value; }).includes('public'))?{key:"item.public",fn:function(ref){
var item = ref.item;
return [_c('div',{class:("visibilite " + (item.public ? 'public' : 'prive'))},[_vm._v(" "+_vm._s(item.public ? "public" : "privé")+" ")])]}}:null,(_vm.titresTable.map(function (header) { return header.value; }).includes('actions'))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"align-center flex-nowrap",attrs:{"no-gutters":""}},[_vm._t("actions",null,{"item":item})],2)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }