<template>
  <div class="pa-2 conteneur-page gradient-bleu">
    <v-card class="pb-2">
      <v-card-title class="align-center">
        <v-text-field
          v-model="search"
          :test-auto="nomPage + '_rechercher'"
          class="align-center"
          autofocus
          prepend-inner-icon="mdi-magnify"
          label="Rechercher"
          clearable
          hide-details
          dense
          outlined
        ></v-text-field>
        <div class="d-flex px-4 my-2">
          <slot name="filtre"></slot>
        </div>
        <v-spacer></v-spacer>
        <!-- <v-divider class="mx-4" vertical></v-divider> -->
        <v-btn
          v-if="labelBoutonAjoutElement !== '' && estResponsable"
          :test-auto="nomPage + '_bouton_cree'"
          class="gradient-bleu"
          dark
          @click="$emit('ajout-element')"
        >
          <v-icon> mdi-plus-circle-outline </v-icon>
          <span v-show="$vuetify.breakpoint.smAndUp">
            {{ labelBoutonAjoutElement }}
          </span>
        </v-btn>
        <!-- <v-divider class="mx-4" vertical></v-divider> -->
      </v-card-title>
      <v-data-table
        :headers="titresTable"
        :header-props="headerProps"
        :footer-props="footerProps"
        :items="itemsTable"
        :height="computeHeightForTable"
        :item-key="keyName"
        class="elevation-2"
        dense
        fixed-header
        :loading="loading"
        :mobile-breakpoint="mobileBreakpoint"
        :sort-by="defaultSortBy"
        :search="search"
        @click:row="(e) => $emit('ligne-selectionnee', e)"
      >
        <!-- Entourer role, actif et public avec des balises div pour un formattage 'chip' cf. processCssClasses -->
        <!-- Anti-pattern mais pas moyen d'injecter un slot dans un slot depuis le parent -->
        <template
          v-if="titresTable.map((header) => header.value).includes('role')"
          v-slot:[`item.role`]="{ item }"
        >
          <div :class="`role role-id-${item.idRole}`">{{ item.role }}</div>
        </template>
        <template
          v-if="titresTable.map((header) => header.value).includes('actif')"
          v-slot:[`item.actif`]="{ item }"
        >
          <div :class="`etat ${item.actif ? 'actif' : 'desactive'}`">
            {{ item.actif ? "actif" : "désactivé" }}
          </div>
        </template>
        <template
          v-if="titresTable.map((header) => header.value).includes('public')"
          v-slot:[`item.public`]="{ item }"
        >
          <div :class="`visibilite ${item.public ? 'public' : 'prive'}`">
            {{ item.public ? "public" : "privé" }}
          </div>
        </template>

        <!-- Slot permettant d'insérer un ensemble d'actions pour chaque lignes -->
        <template
          v-if="titresTable.map((header) => header.value).includes('actions')"
          v-slot:[`item.actions`]="{ item }"
        >
          <v-row no-gutters class="align-center flex-nowrap">
            <slot name="actions" :item="item"> </slot>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ENUMS from "@/utils/enums";
export default {
  props: {
    labelBoutonAjoutElement: {
      type: String,
      default: "",
    },
    // TODO: implement server-side paginate and sort (with search)
    // https://vuetifyjs.com/en/components/data-tables/#server-side-paginate-and-sort
    loading: {
      type: Boolean,
      default: false,
    },
    defaultSortBy: {
      type: String,
      default: "id",
    },
    keyName: {
      type: String,
      default: "id",
    },
    itemsTable: {
      type: Array,
      default: () => [],
    },
    titresTable: {
      type: Array,
      default: () => [],
    },
    mobileBreakpoint: {
      type: Number,
      default: 600,
    },
    nomPage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    ENUMS: ENUMS,
    search: "",
    headerProps: {
      sortByText: "Trier par...",
    },
    windowHeight: 0,
    windowWidth: 0,
  }),
  computed: {
    ...mapGetters("utilisateur", ["estResponsable"]),
    computeHeightForTable: function() {
      // 230 is approximatly a bit more than headers and footers combined
      return this.windowHeight - 205 + "px";
    },
    // Configuration dynamique pour la version mobile
    footerProps: function() {
      return {
        itemsPerPageOptions:
          this.windowWidth <= this.mobileBreakpoint ? [20] : [20, 50, -1],
        showCurrentPage: true,
        showFirstLastPage: this.windowWidth > this.mobileBreakpoint,
        disableItemsPerPage: this.windowWidth <= this.mobileBreakpoint,
      };
    },
  },
  watch: {
    //
  },
  created() {
    // Create a listener on window size to the best table-data size
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    //
  },
  methods: {
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
<style>
.nomProjet {
  background-color: rgba(179, 212, 252, 0.5) !important;
  border-color: rgba(179, 212, 252, 0.5) !important;
  color: rgb(0, 64, 135) !important;
  cursor: pointer;
}

td:not(.v-data-table__mobile-row) {
  max-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
td .v-data-table__mobile-row__cell {
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
