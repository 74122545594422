<template>
  <Liste
    :nom-page="nomPage"
    label-bouton-ajout-element="Créer un groupe"
    key-name="idGroupe"
    default-sort-by="nom"
    :items-table="listeGroupesFormateeFiltree"
    :loading="loading"
    :titres-table="headers"
    @ajout-element="ouvrirFormulaireGroupe(0)"
  >
    <template #filtre>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-checkbox
              v-model="afficherUniquementLesGroupesPrives"
              :test-auto="nomPage + '_filtre_groupesPrives'"
              hide-details
              class="mt-0 pt-0 mx-1"
              on-icon="mdi-eye-off"
              off-icon="mdi-eye-outline"
            ></v-checkbox>
          </span>
        </template>
        <span v-if="afficherUniquementLesGroupesPrives"
          >Afficher tous les groupes</span
        >
        <span v-else>Masquer les groupes publics</span>
      </v-tooltip>
    </template>
    <template #actions="actionsSlotProps">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            icon
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click="
              setIdGroupeSelectionne(actionsSlotProps.item.idGroupe).then(() =>
                $router.push('/pointages/groupe')
              )
            "
          >
            <!-- @click="pointagesItem(actionsSlotProps.item)" -->
            <v-icon id="btn-pointage-globale">mdi-calendar-month</v-icon>
          </v-btn>
        </template>
        <span>Accéder aux pointages du groupe</span>
      </v-tooltip>
      <v-tooltip
        v-if="
          utilisateur.ressources
            .map(function(ressource) {
              return ressource.idRessource;
            })
            .includes(actionsSlotProps.item.idRessource) || nomRole == 'ADMIN'
        "
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            icon
            class="mx-2"
            v-bind="attrs"
            @click="ouvrirFormulaireGroupe(actionsSlotProps.item.idGroupe)"
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Modifier le groupe</span>
      </v-tooltip>
    </template>
  </Liste>
</template>
<script>
import Liste from "@/views/ListeGenerique";
import { mapActions, mapGetters, mapState } from "vuex";
import { EventHub } from "@/event-hub.js";

export default {
  components: {
    Liste,
  },
  data: () => ({
    nomPage: "groupe",
    loading: true,
    afficherUniquementLesGroupesPrives: false,
  }),
  computed: {
    ...mapState("entreprise", ["groupes", "entreprises", "utilisateurs"]),
    ...mapState("utilisateur", ["utilisateur"]),
    ...mapGetters("utilisateur", ["nomRole"]),
    ...mapGetters("entreprise", [
      "getPrenomNom",
      "getNomEntrepriseByidRessource",
    ]),
    headers: function() {
      const headers = [
        { text: "Nom", value: "nom", align: "start" },
        {
          text: "Responsable",
          value: "nomResponsable",
          align: "start",
        },
        {
          text: "Visibilité",
          value: "public",
          sortable: true,
          align: "center",
          width: "120px",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "100px",
        },
      ];
      if (this.utilisateur.ressources.length > 1 || this.nomRole === "ADMIN") {
        headers.splice(2, 0, {
          text: "Entreprise",
          value: "entreprise",
        });
      }
      return headers;
    },
    listeGroupesFormateeFiltree: function() {
      const listeGroupesFormatee = Object.values(this.groupes).map(
        (groupe) => ({
          ...groupe,
          entreprise: this.getNomEntrepriseByidRessource(groupe.idRessource),
          nomResponsable: `${this.getPrenomNom(
            groupe.idRessource,
            true,
            false
          )}`,
        })
      );
      let listeGroupesFormateeFiltree = listeGroupesFormatee;

      if (this.afficherUniquementLesGroupesPrives) {
        listeGroupesFormateeFiltree = listeGroupesFormateeFiltree.filter(
          (groupe) => !groupe.public
        );
      }

      return listeGroupesFormateeFiltree;
    },
    //
  },
  watch: {
    //
  },
  created() {
    this.recupererGroupes()
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },
  mounted() {},
  methods: {
    ...mapActions("entreprise", ["recupererGroupes"]),
    ...mapActions("utilisateur", ["setIdGroupeSelectionne"]),
    ouvrirFormulaireGroupe(idGroupe) {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "groupe",
        id: idGroupe,
      });
    },
    //
  },
};
</script>
<style>
.visibilite {
  padding: 2px 4px 2px 4px;
  border-radius: 20px;
}
.public {
  color: #3c91a0;
  background-color: #bed3c371;
}
.prive {
  color: #cc5b5d;
  background-color: #ebaca26c;
}
/* Gestion affichage mobile des chips */
.v-data-table__mobile-row__cell .visibilite {
  padding: 4px 16px 4px 16px;
}
</style>
