var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Liste',{attrs:{"nom-page":_vm.nomPage,"label-bouton-ajout-element":"Créer un groupe","key-name":"idGroupe","default-sort-by":"nom","items-table":_vm.listeGroupesFormateeFiltree,"loading":_vm.loading,"titres-table":_vm.headers},on:{"ajout-element":function($event){return _vm.ouvrirFormulaireGroupe(0)}},scopedSlots:_vm._u([{key:"filtre",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-checkbox',{staticClass:"mt-0 pt-0 mx-1",attrs:{"test-auto":_vm.nomPage + '_filtre_groupesPrives',"hide-details":"","on-icon":"mdi-eye-off","off-icon":"mdi-eye-outline"},model:{value:(_vm.afficherUniquementLesGroupesPrives),callback:function ($$v) {_vm.afficherUniquementLesGroupesPrives=$$v},expression:"afficherUniquementLesGroupesPrives"}})],1)]}}])},[(_vm.afficherUniquementLesGroupesPrives)?_c('span',[_vm._v("Afficher tous les groupes")]):_c('span',[_vm._v("Masquer les groupes publics")])])]},proxy:true},{key:"actions",fn:function(actionsSlotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.setIdGroupeSelectionne(actionsSlotProps.item.idGroupe).then(function () { return _vm.$router.push('/pointages/groupe'); }
            )}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"id":"btn-pointage-globale"}},[_vm._v("mdi-calendar-month")])],1)]}}],null,true)},[_c('span',[_vm._v("Accéder aux pointages du groupe")])]),(
        _vm.utilisateur.ressources
          .map(function(ressource) {
            return ressource.idRessource;
          })
          .includes(actionsSlotProps.item.idRessource) || _vm.nomRole == 'ADMIN'
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.ouvrirFormulaireGroupe(actionsSlotProps.item.idGroupe)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier le groupe")])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }